// $base-theme: Material;
$skin-name: SSCL;
$border-radius: 0px;
$primary: #37474f;

// $accent: #0f0f59;
// $info: #4cc6ff;
// $success: #5ec232;
// $warning: #ffc107;
// $error: #f42a4d;
// $text-color: #37474f;
// $bg-color: #ffffff;
// $base-text: #37474f;
// $base-bg: #f6f6f6;
// $hovered-text: #37474f;
// $hovered-bg: #ededed;
// $selected-text: #ffffff;
// $selected-bg: #0f0f59;
// $series-a: #4cc6ff;
// $series-b: #ea40ac;
// $series-c: #8600d9;
// $series-d: #46eaa7;
// $series-e: #82d8ff;
// $series-f: #f079c4;
