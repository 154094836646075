@import "~sanitize.css";
@import "theme";

* {
	outline: none;
}

// override masthead z-index
.app-wrapper > div {
  z-index: 0;
}

main {
  z-index: -1; // push main content lower so masthead dropdown menus are not truncated
	position: absolute;
	top: $masthead-height;
  width: 100%;
  height: calc(100vh - #{$masthead-height});
}

.full-height {
  height: calc(100vh - #{$masthead-height});
}

.text {

	&-small {
		font-size: 85%;
		letter-spacing: .33px;
  }

	&-muted {
    // font-weight: 300;
    color: $gray-50;
  }

  &-bold {
    font-weight: 500;
  }

  &-truncate{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

}

.loading {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.react-tooltip {
  max-width: 30vw;
  letter-spacing: unset;
  font-size: 11px;
  font-weight: 500; //change to 400 if antialiased is not applied at the body level
  padding: 0.75rem;

  &.place-left {
    &:after {
      right: -5px;
    }
  }
  &.place-right {
    &:after {
      left: -5px;
    }
  }
}
