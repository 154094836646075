@mixin fontFace($family, $weight, $style, $src) {
	@font-face {
		font-family: $family;
		font-weight: $weight;
		font-style: $style;
		src:
			url("#{$src}.woff2") format("woff2"), // standard
			url("#{$src}.woff")  format("woff")   // fallback for IE11 and Safari 11 on El Capitan and older
		;
	}
}
