.k-grid {
  border: none;
  font-family: inherit;

  tbody {

    tr {

      &.k-state-hover,
      &:hover {
        color: $gray;
      }

    }

  }

  .k-grid-header,
  .k-grid-container {
    color: $gray;
  }

  .k-grid-header-wrap, .k-grid-footer-wrap {
    border: none;
  }

  .k-grid-header {
    font-size: 11px;
    letter-spacing: 0.33px;
    text-transform: uppercase;

    th {
      font-weight: 600;
      padding: 8px 12px;
      line-height: 1.42;
    }

    tr {
      &:hover {
        background-color: transparent;
      }

      .k-header {
        white-space: normal;

        &.k-sorted {
          background-color: rgba(0, 0, 0, 0.08);
        }

        .k-link {
          margin: 0;
          padding: 0;
          height: auto;
          display: flex;

          .k-icon, .k-sort-order {
            align-self: flex-end;
            white-space: nowrap;
          }

          .k-icon {
            flex-basis: 16px;
          }

          .k-sort-order {
            color: rgba(0, 0, 0, .6);
            font-weight: 300;
            // margin-left: 3px;
          }
        }

      }

    }

  }

  .k-grid-container {
    font-size: 13px;
    letter-spacing: 0.2px;
    line-height: 1;

    tr {

      td {
        border: 1px solid transparent;
        padding: 8px 11px;
        white-space: nowrap;
      }

      &.k-state-selected {
        background-color: $digital-orange-15;
        box-shadow: $digital-orange-50 0px -1px 0px 0px inset, $digital-orange-50 0px -1px 0px 0px;

        td {
          color: $gray;
        }
      }

      &.k-alt:not(.k-state-selected) {
        background-color: $gray-row-background;
      }

      &:hover:not(.k-state-selected) {
        background-color: $bright-blue-15;
        box-shadow: $bright-blue-30 0px -1px 0px 0px inset, $bright-blue-30 0px -1px 0px 0px;
      }

    }

  }

}
