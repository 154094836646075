.swal2-popup {

	.swal2-content {
		font-size: 1.25rem;
		font-weight: 400;
	}

	.swal2-actions {
    padding: 2rem;

    button {
      font-size: 13px !important;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.05em;

      &.swal2-confirm {
        background-color: rgb(130, 216, 255);
        color: rgb(15, 15, 89);

        &:active {
          background-color: rgb(76, 198, 255);
        }
        &:focus {
          box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(130, 216, 255) 0px 0px 0px 3px;
        }
      }

      &.swal2-cancel {
        border: 1px solid rgb(55, 71, 79);
        background: none transparent;
        color: rgb(55, 71, 79);

        &:focus {
          box-shadow: rgb(255, 255, 255) 0px 0px 0px 1px, rgb(55, 71, 79) 0px 0px 0px 3px;
        }
      }

    }

	}

}
